import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import { RichText } from "prismic-reactjs"

const Heading = styled.h1`
  padding: 0 1rem;
`
const A = styled.a`
  padding: 0 1rem;
  color: black;
`
const P = styled.p`
  padding: 0 1rem;
`
const Html = styled.div`
  padding: 0 1rem;
  a {
    color: #83b413;
  }
`
const Logo = styled.img`
  object-fit: contain;
  object-position: 50% 50%;
  width: 50%;
`
const Logos = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
  > div {
    width: 50%;
  }
  > div:first-child {
    background: black;
  }
`
const StyledBackground = styled.img`
  height: 90vh;
  width: 100vw;
  position: absolute;
  object-fit: contain;
  object-position: 0 0;
  top: 5rem;
  z-index: -1;
`

class Kontakt extends React.Component {
  render() {
    const data = this.props.data.presse.allPresses.edges[0].node
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Presse" />
        <Heading>Presse Information Ausstellung Tino Sehgal </Heading>
        <Html>{RichText.render(data.inzukunftText)}</Html>
        <A href={data.inzukunftPdf.url}>
          Pressemitteilung Ausstellung Tino Sehgal
        </A>
        <Heading>Press Information for the GLOBART Academy 2021 SINN </Heading>
        <A href={data.presspdf.url}>
          Press Release GLOBART Academy 2021 SINN
        </A>
        <Heading>Logos:</Heading>
        <Logos>
          <Logo src={data.whiteLogo.url} />
          <Logo src={data.blackLogo.url} />
        </Logos>
        <div>Usage: Web (Print Logo on request)</div>
      </Layout>
    )
  }
}
export default Kontakt

export const pageQuery = graphql`
         query PresseQueryEn {
           presse: prismic {
             allPresses(lang: "en-gb") {
               edges {
                 node {
                   text
                   inzukunftText
                   inzukunftPdf {
                     ... on PRISMIC__FileLink {
                       _linkType
                       name
                       size
                       url
                     }
                   }
                   presspdf {
                     ... on PRISMIC__FileLink {
                       _linkType
                       name
                       size
                       url
                     }
                   }
                   blackLogo
                   logoZip {
                     ... on PRISMIC__FileLink {
                       _linkType
                       name
                       size
                       url
                     }
                   }
                   pdf {
                     ... on PRISMIC__FileLink {
                       _linkType
                       name
                       size
                       url
                     }
                   }
                   text
                   visual
                   whiteLogo
                   pressefotos {
                     fotos
                   }
                   _meta {
                     id
                   }
                 }
               }
             }
           }
         }
       `
